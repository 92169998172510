module services {
    export module purchase {
        export class deliveryInstructionService implements interfaces.purchase.IDeliveryInstructionService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            getDetail(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstruction> {

                return this.$resource<interfaces.purchase.IDeliveryInstruction>(this.ENV.DSP_URL + "DeliveryInstruction/GetDetail", {
                    Id: '@Id',
                    ordId: '@ordId'
                }, {
                        query: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: false
                        }
                    });
            }

            getCostingLineListForMultipleCostings(): ng.resource.IResourceClass<interfaces.costing.ICostingLineModel> {

                return this.$resource<interfaces.costing.ICostingLineModel>(this.ENV.DSP_URL + "DeliveryInstruction/GetCostingLineListForMultipleCostings", {
                    diId: '@diId',
                }, {
                        query: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: true
                        }
                    });
            }

            calculateDeliveryInstructionCost(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstruction/calculateDeliveryInstructionCost", {
                    di: '@di',
                    deliveryInstructionSkuAllocationId: '@deliveryInstructionSkuAllocationId'
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstruction/Save");
            }

            getCostingSKUs(ordId: number): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionSKUAllocation> {
                return this.$resource<interfaces.purchase.IDeliveryInstructionSKUAllocation>(this.ENV.DSP_URL + "DeliveryInstruction/GetCostingSKUs", {
                    ordId: ordId
                });
            }

            
            getListByPOId(purchaseOrderId: number): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionSKUAllocation> {
                return this.$resource<interfaces.purchase.IDeliveryInstructionSKUAllocation>(this.ENV.DSP_URL + "DeliveryInstruction/GetListByPOId", {
                    purchaseOrderId: purchaseOrderId
                });
            }

        


            removeSKUAllocation(pdsId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstruction/RemoveSKUAllocation",{
                    pdsId: pdsId
                });
            }

            recalculateExchangeRate(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstruction> {
                return this.$resource<interfaces.purchase.IDeliveryInstruction>(this.ENV.DSP_URL + "DeliveryInstruction/RecalculateExchangeRate", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            CheckForPR(diId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstruction/CheckForPR", {
                    diId: diId,
                });
            }

            RunQuotaAllocation(diId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DeliveryInstruction/RunQuotaAllocation", {
                    diId: diId,
                });
            }

            GetPermitsForDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DeliveryInstruction/GetPermitsForDropdown", {
                    deliveryInstructionRestrictionId: '@deliveryInstructionRestrictionId',
                    searchText: '@searchText'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetDeliveryInstructionPRListExcel(diId: number, showOnlyProhibited: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'DeliveryInstruction/GetDeliveryInstructionPRListExcel?diId=' + diId + '&showOnlyProhibited=' + showOnlyProhibited;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            ViewDetailPR(): ng.resource.IResourceClass<interfaces.purchase.IDeliveryInstructionPRDisplay> {
                var viewDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return this.$resource<interfaces.sales.ISalesOrderPRDisplay>(this.ENV.DSP_URL + "DeliveryInstruction/ViewDetailPR", {
                    diId: '@diId',
                    showOnlyProhibited: '@showOnlyProhibited',
                }, {
                    query: viewDetail
                });
            }
        }
    }
    angular.module("app").service("deliveryInstructionService", services.purchase.deliveryInstructionService);
}